@import url(https://fonts.googleapis.com/css?family=Libre%20Franklin:300,400,500,700);

body {
  margin: 0;
  font-family: 'Libre Franklin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'DXIcons';
  src: local('☺'), url('./themes/fonts/dxicons.woff') format('woff'), url('./themes/fonts/dxicons.ttf');
  font-weight: normal;
  font-style: normal;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 5px;
  li {
    padding-left: 5px;
    list-style: none;
  }
}

.dx-toast-content {
  width: 400px !important;
}
